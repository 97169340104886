// If you want to override variables do it here
@import "variables";
// Import styles
@import "~@coreui/coreui/scss/coreui";
// Import lightgallery css files
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-thumbnail.css');

// If you want to add something do it here
@import "custom";

.app-body {
    overflow-y: hidden;
}

.app-footer {
    padding: 1rem;
}

.btn:focus,
.btn.focus {
    box-shadow: none;
}

.btn-action {
    font-size: 22px;
    line-height: 0.5;
    padding: 8px;
}

.sidebar .nav-dropdown-items {
    margin-left: 15px;
    border-left: 1px solid;
}

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

// body {
//     font-family: Roboto, Arial, sans-serif;
//     margin: 0;
// }
.basic-container {
    padding: 30px;
}

.app-header .navbar-brand {
    width: 200px !important;
}

.navbar-toggler {
    display: none !important;
}

html:not([dir=rtl]) .sidebar {
    margin-left: -185px;
}

.version-info {
    font-size: 8pt;
    float: right;
}

// @import "~ag-grid-community/dist/styles/ag-grid.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham.css";

// cursor pointer
.pointer {
    cursor: pointer;
}

// Material select look like bootstrap select
.mat-form-field-appearance-legacy .mat-form-field-infix {
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 1.6em;
    left: 10px;
}

// Material Select Select-All
.select-all-material-select {
    border-bottom: 1px solid;

    label {
        margin: 0;
    }

    .mat-checkbox {
        display: block;
        padding: 5px 17px;

        label {
            display: block;
        }
    }
}

.multiselect-dropdown .dropdown-btn .selected-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ngx-timepicker {
    border-bottom: none !important;
}

.timepicker-backdrop-overlay,
.timepicker-overlay {
    z-index: 9999 !important;
}

.formio-form {
    ul.pagination {
        display: none;
    }

    .h-350px-scrollable {
        height: 350px;
        overflow: auto;
        padding: 10px 20px;
        border: 1px solid #000000;
    }
}


@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }
}

@media (max-width: 1023px) {

    // Hide main verdentum logo
    .app-header .navbar-brand {
        display: none !important;
    }
}


/* Dragula CSS Starts */
/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

/* Dragula CSS Ends */

.tippy-content {
    padding: 5px;
    border: 1px solid;
    border-radius: 5px;
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

/* HR CSS */
/* Gradient color1 - color2 - color1 */
hr.gradient {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

/* Gradient transparent - color - transparent */
hr.gradient-transparent {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

/* Double-color dashed line */
hr.dashed-line {
    border: 0;
    border-bottom: 1px dashed #ccc;
    background: #999;
}

/* Inset, by Dan Eden */
hr.inset {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* Carousel CSS */
carousel:hover {
    ol.carousel-indicators {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.text-underline {
    text-decoration: underline;
    text-underline-offset: 6px;
    text-underline-position: under;
}

/* Table Header CSS */
.table-colored {
    thead>tr>th {
        color: #FFFFFF;
        background-color: #4D6C87;
    }
}


// Button CSS
.btn-primary {
    color: #fbfafc;
    background-color: #5542f6;
    border-color: #5542f6;
    border-radius: 4px;
    font-weight: 500;
}
.btn-primary:hover {
    background-color: #4633f1 !important;
    border-color: #4633f1 !important;
}
.btn-primary:focus {
    color: #fff;
    background-color: #5542f6;
    border-color: #5542f6;
    box-shadow: 0 0 0 .2rem  rgb(13 0 255 / 99%) !important;
}
.btn-primary:active {
    color: #fff !important;
    background-color: #5542f6 !important;
    border-color: #4633f1 !important;
}

.btn-primary:disabled {
    color: #fcfcfd;
    background-color: #4031b9;
    border-color: #4031b9;
}

.btn-outline-primary {
    color: #5542f6;
    background-color: transparent;
    border-color: #5542f6;
    border-radius: 4px;
    font-weight: 500;
}
.btn-outline-primary:hover {
    color: #fbfafc !important;
    background-color: #5542f6 !important;
    border-color: #5542f6;
}
.btn-outline-primary:focus {
    color: #5542f6;
    background-color: transparent;
    border-color: #5542f6 !important;
    box-shadow: 0 0 0 .2rem #5a49f3 !important;
}
.btn-outline-primary:active {
    color: #fbfafc !important;
    background-color: #5542f6 !important;
    border-color: #5542f6 !important;
  }
.btn-outline-primary:disabled {
    color: #6859f2;
    background-color: transparent;
    border-color: #6859f2;
}

.btn-outline-primary-change {
    color: #5542f6;
    background-color: #fbfafc;
    border-color: #fbfafc;
    font-weight: 500;
}
.btn-outline-primary-change:hover {
    color: #5542f6 !important;
    background-color: transparent !important;
    border-color: #fbfafc !important;
}
.btn-outline-primary-change:focus {
    color: #5542f6;
    border-color: #fbfafc;
}
.btn-outline-primary-change:active {
    background-color: #fbfafc !important;
}
.btn-outline-primary-change:disabled {
    color: #5542f6 !important;
    background-color: #dfdedf !important;
    border: none;
}

.btn-cancel {
    color: #2e2c34;
    border: solid 1px #ebeaed;
    background-color: #fff;
    border-radius: 4px;
    font-weight: 500;
}
.btn-cancel:hover {
    color: #2e2c34 !important;
    border: solid 1px #ebeaed !important;
    background-color: #fff !important;
}
.btn-cancel:focus {
    background-color: #f4f2f2 !important;
    border: solid 1px #ebeaed !important;
    color: #2e2c34 !important;
    box-shadow: none !important;
    outline: none !important;
}
.btn-cancel:disabled {
    color: #343337;
    background-color: #fff;
    border: solid 1px #ebeaed;
}

.btn-red {
    color: #fff;
    background-color: #fc3400;
    font-weight: 500;
}
.btn-red:hover {
    color: #fff;
    background-color: #d43f19;
}
.btn-red:focus {
    color: #fff !important;
    background-color: #d43f19 !important;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}
.btn-red:disabled {
    color: #fff;
    background-color: #de3a11;
}

a {
    color: #5542f6;
}
a:hover {
    color: #4633f1;
}

.text-primary {
    color: #5542f6 !important; 
}
.text-primary:hover {
    color: #4633f1 !important; 
}

a.text-primary:hover {
    color: #5542f6 !important; 
}
 a.text-primary:focus {
    color: #2006f2 !important; 
 }
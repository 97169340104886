// Here you can add other styles
.popover {
    border: 1px solid #6CC00C !important;
}
.popover-header {
    color: #FFFFFF !important;
    background-color: #6CC00C !important;
}
.bs-popover-auto[x-placement^=top]>.arrow:before, .bs-popover-top>.arrow:before {
    border-top-color: #6CC00C !important;
    border-bottom-color: #6CC00C !important;
}
.bs-popover-auto[x-placement^=bottom]>.arrow:after, .bs-popover-bottom>.arrow:after {
    border-top-color: #6CC00C !important;
    border-bottom-color: #6CC00C !important;
}

// PrimeNG Input Text
.p-inputtext {
    padding: 0.30rem 0.75rem !important;
}

// PrimeNG Multiselect
::ng-deep .p-multiselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}
::ng-deep .p-multiselect {
    min-width: 15rem;
    width: 18rem;
}
::ng-deep .p-multiselect {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
}
::ng-deep .p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
::ng-deep .p-multiselect-label-container {
    overflow: hidden;
    flex: 1 1 auto;
    cursor: pointer;
}
::ng-deep .p-multiselect .p-multiselect-label.p-placeholder {
    color: #6c757d;
}
.p-multiselect .p-multiselect-label {
    padding: 0.30rem 0.75rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding: 0.30rem 0.75rem !important;
}
::ng-deep .p-multiselect-label {
    display: block;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}
::ng-deep .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #6c757d;
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
::ng-deep .p-multiselect-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
::ng-deep .p-multiselect .p-multiselect-panel {
    min-width: 100%;
}
::ng-deep .p-multiselect-panel {
    position: absolute;
    top: 0;
    left: 0;
}
::ng-deep .p-multiselect-panel .p-multiselect-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
::ng-deep .p-multiselect-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
::ng-deep .p-multiselect-items-wrapper {
    overflow: auto;
}

a:hover { text-underline-offset: 5px; }